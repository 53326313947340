import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AdminManagementAddEditComponent } from '../admin-management-add-edit/admin-management-add-edit.component';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { AuthService } from '@app/services/auth/auth.service';
import { LocalizationService } from '../internationalization/localization.service';
import { UserService } from '@app/services/user/user.service';
import { User } from '@app/models/user.model';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { NavigationService } from '@app/services/navigation/navigation.service';

export interface ExtendedAdminUser {
  user: User,
  edit: any
}

@Component({
  selector: 'app-admin-management-list',
  templateUrl: './admin-management-list.component.html',
  styleUrls: ['./admin-management-list.component.scss'],
})
export class AdminManagementListComponent implements OnInit, AfterViewInit{
  loginUser: User | undefined;
  adminUsers: User[] = [];
  displayedAdminColumns: string[] = ['emailAddress'];
  filteredUsers: MatTableDataSource<ExtendedAdminUser> = new MatTableDataSource<ExtendedAdminUser>([]);
  editToolTip: string = "";
  searchControl: UntypedFormControl = new UntypedFormControl(null);
  debounce: number = 1000;

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private _authService: AuthService,
    private _userService: UserService,
    private _router: Router,
    private _localizationService: LocalizationService,
    private _navigationService: NavigationService,
    private _addDialog: MatDialog
  ) { 
    this._navigationService.onChange(true, false);
    this.editToolTip = this._localizationService.translate("admin_management_list_edit_tooltip");
  }

  ngAfterViewInit() {
    this.filteredUsers.paginator = this.paginator;
  }

  ngOnInit() {
    this.loadUsers();
    this.searchControl.valueChanges
      .pipe(debounceTime(this.debounce), distinctUntilChanged())
      .subscribe(query => {
        if (query) {
          if (query.length > 2) {
            let queryLower = query.toLowerCase();
            this.filteredUsers.data = this.adminUsers.filter(x =>
              x.emailAddress.toLowerCase().includes(queryLower))
              .map(u => { return { 
                user: u,
              } as ExtendedAdminUser})

            if (this.filteredUsers.paginator) {
                this.filteredUsers.paginator.firstPage();
            }
          }
        } else {
          this.filteredUsers.data = this.adminUsers.map(u => { return { 
            user: u,
          } as ExtendedAdminUser})
        }
      });
  }

  loadUsers(): void {
    this._authService.getUser()
      .then(user => {
        if (user) {
          this._userService.getAdminUsers().subscribe({
            next: (response => {
              if (response) {
                this.loginUser = user;
                this.adminUsers = response;
                this.filteredUsers.data = response
                .map(u => { return { 
                  user: u,
                } as ExtendedAdminUser})
              }
            })
          });
        }
      },
      _ => console.log("Failed to get users")
    );
  }

  addUser() {
    this._userService.getAdminRoles().subscribe({
      next: (response => {
        if (response) {
          let addUserDialog: MatDialogRef<AdminManagementAddEditComponent>;
          const dialogConfig = {
            data: { loginUser: this.loginUser, user: {} as User, adminUsers: this.adminUsers,
                    roles: response, isEditMode: false },
            disableClose: true,
            autoFocus: false,
            maxWidth: '100vw !important',
            maxHeight: '100vw !important',
            panelClass: 'details-container'
          };
          addUserDialog = this._addDialog.open(AdminManagementAddEditComponent, dialogConfig);
          addUserDialog.addPanelClass('details-container');
          addUserDialog.afterClosed().subscribe(_ => this.loadUsers());
        }
      })
    });
     
  }

  editUser(user: User | undefined) {
   
  }

  openSearch() {
    this.searchControl.setValue('');
  };

  closeSearch() {
    this.searchControl.setValue(null);
  };

  showSearchButton() {
    return this.searchControl.value === null;
  };

  showSearchInput() {
    return this.searchControl.value !== null;
  };

  closeAdminManagement(): void {
    this._router.navigate(['signin']);
  }

  onSortChange(event: any) {
    if (!event.active || event.direction === '') {
      return;
    }

    this.filteredUsers.data = this.filteredUsers.data.sort((a, b) => {
      const isAsc = event.direction === 'asc';
      switch (event.active) {
        case 'emailAddress':
          return this.compare(a.user.emailAddress, b.user.emailAddress, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
