
<form [formGroup]="radioButtonFormGroup">
  <mat-label class="color-dark-blue" id="{{question.uniqueHtmlIdentifier}}">{{question.question.caption}}</mat-label>
  <mat-label *ngIf="question.question.isRequired" class="color-city-red"> *</mat-label>
  <div>
    <mat-radio-group
      aria-labelledby="radio-group-label"
      [(ngModel)]="question.answer" formControlName="radioButtonGroup">
      <div *ngFor="let option of question.question.additionalPropertiesParsed.Options; let indexOfelement=index;">
        <mat-radio-button [value]="option" id="questionOption{{question.uniqueHtmlIdentifier}}{{indexOfelement}}">
          <div *ngIf="question.question.additionalPropertiesParsed.OptionsDescription == null">
            <mat-label class="font-size-body-text city-teal-dark">
              {{option}}
            </mat-label>
          </div>
          <div *ngIf="question.question.additionalPropertiesParsed.OptionsDescription != null">
            <mat-label class="city-teal-dark">
              {{question.question.additionalPropertiesParsed.OptionsDescription[indexOfelement] != null && question.question.additionalPropertiesParsed.OptionsDescription[indexOfelement].length > 0 ? question.question.additionalPropertiesParsed.OptionsDescription[indexOfelement] : option}}
            </mat-label>
          </div>
        </mat-radio-button>
      </div>
    </mat-radio-group>
    <mat-error *ngIf="hasError(radioButtonFormGroup, 'radioButtonGroup')">{{getError(radioButtonFormGroup, 'radioButtonGroup')}}</mat-error>
  </div>
</form>