import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { User } from 'src/app/models/user.model';
import { SiteInductionInfo } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LocalizationService } from 'src/app/components/internationalization/localization.service';
import { SiteService } from 'src/app/services/site/site.service';
import { Site } from 'src/app/models/site.model';
import { DatePipe } from '@angular/common';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';
import { FileStorageService } from 'src/app/services/filestorage/filestorage.service';
import { ConfigurationService } from '@app/services/configuration/configuration.service';
import { FormService } from '@app/services/form/form.service';
import { ComplianceCheckAnswerModel } from '@app/models/inductionqna.model';
import { CityComplianceStatus, DefaultCompanyType, DisplayedComplianceStatus } from '@app/shared/enums';

@Component({
  selector: 'app-cityonsiteprofile',
  templateUrl: './cityonsiteprofile.component.html',
  styleUrls: ['./cityonsiteprofile.component.scss']
})

export class CityonsiteprofileComponent implements OnInit {
  currentUser: User | undefined;
  profileImgLink: string | undefined;
  public siteInductionInfoList: SiteInductionInfo[] = [];
  displayfullname: string | undefined;
  displayinductionno: string | undefined;
  displayinductionexpirydate: string | undefined;
  currentSite: Site | undefined;
  public siteInductionInfo: SiteInductionInfo[] = [];
  profileInductionModuleEnable = false;
  profileRapidGlobalRequired = false;
  objCompliance: ComplianceCheckAnswerModel | undefined;
  defaultNoCompanySpecified: string = DefaultCompanyType.NO_COMPANY_SPECIFIED;
  defaultCityFMCompanyName: string = DefaultCompanyType.CITYFM;
  enumCityComplianceStatus = CityComplianceStatus;
  
  statusCompliant = this._localizationService.translate(DisplayedComplianceStatus.statusCompliant);
  statusNotCompliant = this._localizationService.translate(DisplayedComplianceStatus.statusNotCompliant);
  statusNotApplicable = this._localizationService.translate(DisplayedComplianceStatus.statusNotApplicable);

  @Input('question') question: any;

  constructor(private _authService: AuthService,
    private _siteService: SiteService,
    public _router: Router,
    private _localizationService: LocalizationService,
    public datepipe: DatePipe,
    private _fileStorageService: FileStorageService,
    private _configurationService: ConfigurationService,
    private _formService: FormService) {

  }

  async ngOnInit() {
    this.profileInductionModuleEnable = await this._configurationService.getProfileInductionRequired();
    this.profileRapidGlobalRequired = await this._configurationService.getProfileRapidGlobalRequired();

    await this.loadSite();
    await this.loadComplianceCheck();

    var user = await this._authService.getUser()
    if (user) {
      this.currentUser = user;
      this.displayfullname = `${user.firstName} ${user.lastName}`;
      this.getProfileImageLink(user.photo);
      this.siteInductionInfoList = user.siteInductionInfos;
      this.siteInductionInfo = this.siteInductionInfoList.filter(sii => sii.brandId === this.currentSite?.brand?.id);
      if (this.siteInductionInfo.length == 1) {
        this.displayinductionno = "#" + this.siteInductionInfo[0].inductionId;
        this.displayinductionexpirydate = this.datepipe.transform(this.siteInductionInfo[0].expiryDate, 'dd MMMM YYYY')!
      }
      else {
        this.displayinductionno = "";
        this.displayinductionexpirydate = "";
      }
    }
  }

  async loadComplianceCheck(){
    if(this.profileRapidGlobalRequired){
      const inductionForm = await this._formService.getInductionConfig();
      const complianceCheckQnAs = inductionForm.inductionConfigDetails.filter(i => i.type.toLowerCase() == "compliancecheck");

      if(complianceCheckQnAs && complianceCheckQnAs.length > 0){
        if (complianceCheckQnAs[0]?.answer) {
          this.objCompliance = JSON.parse(complianceCheckQnAs[0]?.answer) as ComplianceCheckAnswerModel;
        }
      }
    }
  }


  async loadSite() {
    var site = await this._siteService.getSignedInSite();
    if (site) {
      this.currentSite = site;
    } else {
      this._siteService.removeSignedInSite();
      this._router.navigate(['signin']);
    }
  }

  getCompliantStatus(cityComplianceStatus : number | undefined): string {
    var status = "";
    if(cityComplianceStatus){
      switch (cityComplianceStatus) {
        case CityComplianceStatus.COMPLIANT:
          status = this.statusCompliant;
          break;
        case CityComplianceStatus.NOTCOMPLIANT:
          status = this.statusNotCompliant;
          break;
        case CityComplianceStatus.NOTAPPLICABLE:
          status = this.statusNotApplicable;
          break;
        default:
      }
    }

    return status;
  }

  private getProfileImageLink(userPhoto: string) {
    if (environment.isDemo) {
      this.profileImgLink = userPhoto;
    } else {
      // Regular expression to check if string is a valid UUID
      const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

      if (!regexExp.test(userPhoto)) {
        this.profileImgLink = userPhoto;
      } else {
        if (this.currentUser) {
          this._fileStorageService.download(userPhoto).subscribe(
            {
              next: r => {
                this.profileImgLink = `data:image/${r.fileType};base64,${r.file}`;
                if (this.currentUser) {
                  this.currentUser.photo = this.profileImgLink;
                  this._authService.setUser(this.currentUser);
                }

              },
              error: e => {
                this.profileImgLink = "";
              }
            });
        } else {
          this.profileImgLink = "";
        }
      }
    }
  }

}
