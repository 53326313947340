<div *ngIf="currentUser?.withJob">
    <div fxLayout="row">
        <div fxLayout="column">
            <span class="font-size-h1 color-city-blue">{{ ('siteinductioncompleted_lbl_welcome' |
                translate).replace("{name}", fullname) }}</span>
            <span class="font-size-body-text color-dark-blue">
                {{ ('siteinductioncompleted_lbl_signed_in_first' | translate) }}
                <b class="display-bold bg-primary-blue">{{selectedSite?.name}}</b>.
                {{ ('siteinductioncompleted_lbl_signed_in_second' | translate) }}
            </span>
        </div>
    </div>
</div>

<div *ngIf="!currentUser?.withJob">
    <div fxLayout="row">
        <div fxLayout="column">
            <span class="font-size-h1 color-city-blue">{{ ('siteinductioncompleted_lbl_welcome' |
                translate).replace("{name}", fullname) }}</span>
            <span class="font-size-body-text color-dark-blue">{{ ('siteinductioncompleted_lbl_signed_in_first' |
                translate) }}
                <b class="display-bold bg-primary-blue">{{selectedSite?.name}}</b>.
                {{ ('siteinductioncompleted_lbl_signed_in_visitor' | translate) }}
            </span>
        </div>
    </div>
</div>

<div class="divider"></div>
<div fxLayout="row">
    <div fxLayout="column" fxFlex="100%">
        <button mat-raised-button (click)="siteSignOutOnClick()" id="btn_sitesignout">
            {{ ('siteinductioncompleted_lbl_signing_out' | translate).replace("{site_name}", selectedSite?.name) }}
        </button>
    </div>
</div>

<div class="divider"></div>
<div class="divider"></div>
<div *ngIf="distinctModules != null && distinctModules.length > 0 && !outOfRange">
    <div fxLayout="row" *ngIf="showProductLabel">
        <div fxLayout="column">
            <span class="font-size-body-text color-dark-blue">
                {{ ('siteinductioncompleted_lbl_view_job' | translate) }}
            </span>
        </div>
    </div>
    <div fxLayout="row">
        <div fxLayout="column" fxFlex="100%">
            <div fxLayout="column" fxFlex="100">
                <div *ngFor="let module of distinctModules; index as i">
                    <div class="divider"></div>
                    <div fxLayout="row" *ngIf="showModule(module)">
                        <button mat-stroked-button name="btn_module_{{module.productName}}"
                            id="btn_module_{{module.productName}}" (click)="moduleOnClick(module)">
                            <img class="site-image" *ngIf="module.productImgUrl" src="{{module.productImgUrl}}" />
                            {{ module.productName }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>