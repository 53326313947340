export enum StorageKey {
  USER = 'USER',
  SITE = 'SITE',
  FORM = 'FORM',
  JOB = 'JOB',
  TOKEN = 'TOKEN',
  REFRESHTOKEN = 'REFRESHTOKEN',
  CONFIGURATION = 'CONFIGURATION',
  MODULE = 'MODULE',
  FILESETTINGS = "FILESETTINGS",
  PROCESSFLOW = "PROCESSFLOW",
  LINKTYPE = "LINKTYPE",
  QRSITE = "QRSITE",
  FIRSTREDIRECT="FIRSTREDIRECT",
  SIGNIN = "SIGNIN",
  OUTOFRANGE = "OUTOFRANGE",
  SITEVISITREASONS = "SITEVISITREASONS",
  OUTOFRANGERETRYCOUNT = "OUTOFRANGERETRYCOUNT",
  OUTOFRANGEPREVIOUSNOTIFICATIONTIME = "OUTOFRANGEPREVIOUSNOTIFICATIONTIME",
  OUTOFRANGENOTIFICATIONSUCCESSFUL = "OUTOFRANGENOTIFICATIONSUCCESSFUL",
  SIGNINMETHOD = 'SIGNINMETHOD'
}

export enum RegisterStatus {
    Step1 = 1,
    Step2,
    Step3
}

export enum ConfigurationKey {
    PROFILERAPIDGLOBALREQUIRED = 'Profile_RapidGlobalRequired',
    PROFILEINDUCTIONREQUIRED = 'Profile_InductionRequired',
    INDUCTIONMODULEENABLE = 'InductionModuleEnable',
    ISSITELISTJOBCOUNTENABLE = 'IsSiteListJobCountEnable',
    BLOCKSEARCHINDEXING = 'BlockSearchIndexing',
    PREVIOUSCONFIGURATIONCHECKTIME = 'PreviousConfigurationCheckTime',
    GOOGLEPLAYURL = 'GooglePlayUrl',
    APPLESTOREURL = 'AppleStoreUrl',
    PRIVACYPOLICYLINK = 'PrivacyPolicyLink',
    TERMSCONDITIONLINK = 'TermsConditionLink',
    JOBLISTBATCHSIZE = 'JobListRenderBatchSize',
    TECHNICALUSERPROFILEGUIDE = 'TechnicianUserProfileGuide',
    USERMANAGEMENTGUIDE = 'UserManagementGuide',
    SUBCONTRACTORVISITSTOCOLESDCSGUIDE = 'SubcontractorVisitsToColesDCsGuide',
    OUTOFRANGENOTIFICATIONINTERVALINMINUTES = 'OutOfRangeNotificationIntervalInMinutes',
    OUTOFRANGENOTIFICATIONMAXRETRYCOUNT = 'OutOfRangeNotificationMaxRetryCount',
    NEEDHELPDOCURL = 'NeedHelpDocUrl',
}

export enum UserType {
    BACKEND_USER = 'Backend',
    INTERNAL_USER = 'Internal',
    EXTERNAL_USER = 'External',
    CITY_ADMIN = 'City Admin'
}

export enum CompanyType {
    SELF = 'SELF',
    PARENT = 'PARENT',
    SUBCON = 'SUBCON'
}

export enum FastLinkType {
    Closedown = 1,
    Job
}

export enum InvitationSource {
    NONE = 1,
    RESOURCEADMIN = 2,
    INTERNAL = 3,
    EXTERNAL = 4
}

export enum InvitationType
{
    ADD = 1,
    DELETE = 2
}

export enum JobType {
    REACTIVE_JOB = 'REACTIVE',
    PPM_JOB = 'PPM'
}

export enum Version {
    LATESTAPPVERSION = 'LATESTAPPVERSION',
    ISFORCE = 'ISFORCE',
    PREVIOUSVERSIONCHECKTIME = 'PREVIOUSVERSIONCHECKTIME'
}

export enum AppPlatform {
    WEB = 1,
    MOBILEAPP = 2
}

export enum CityComplianceStatus {
    COMPLIANT = 1 ,
    NOTCOMPLIANT = 2 ,
    NOTAPPLICABLE = 3 ,
}

export enum DisplayedComplianceStatus {
    statusCompliant = "site_compliance_status_compliance",
    statusNotCompliant = "site_compliance_status_non-compliance",
    statusNotApplicable = "site_compliance_status_not_applicable",
}

export enum DefaultCompanyType {
    NO_COMPANY_SPECIFIED = 'No Company Specified',
    CITYFM = 'City FM'
}

export enum SignInMethod {
    QR = 'QR',
    GPS = 'GPS',
    FASTLINK = 'FASTLINK'
}