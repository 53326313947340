import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/custom-validators';
import { LocalizationService } from '../../internationalization/localization.service';

@Component({
  selector: 'app-radiobutton',
  templateUrl: './radiobutton.component.html',
  styleUrls: ['./radiobutton.component.scss']
})
export class RadiobuttonComponent implements OnInit {
  public radioButtonFormGroup!: UntypedFormGroup;
  @Input('question') question : any;
  
  constructor(
    private _formBuilder: UntypedFormBuilder, 
    private _localizationService : LocalizationService
  ) {}

  ngOnInit(): void {
     this.radioButtonFormGroup = this.initFormGroup();
  }

  private initFormGroup(): UntypedFormGroup {
    const loginUserForm = this._formBuilder.group({
      radioButtonGroup: new UntypedFormControl(undefined, [CustomValidators.isRequired(this.question.question.isRequired)])
    });
    return loginUserForm;
  }

  public hasError(form: UntypedFormGroup, controlName: string): boolean {
    const validationOutput = this.getError(form, controlName);
    return validationOutput !== '';
  }

  public getError(form: UntypedFormGroup, controlName: string): string {
    switch (controlName) {
      case 'radioButtonGroup':
        if (this.formHasError(form, controlName, 'isRequired')) {
          return this._localizationService.translate('radiobutton_required');
        }
        break;
    }
    return '';
  }

  private formHasError(form: UntypedFormGroup, controlName: string, errorName: string): boolean {
    return form.controls[controlName].hasError(errorName);
  }
}